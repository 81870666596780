import React from "react";
import {
  Typography,
  Container,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "./TextDecrypt";
import Resume from "../../settings/resume.json";
import { FirstName, LastName } from "../../utils/getName";
import Info from "../section/Info";
import Projects from "../section/Projects";
import Skills from "../section/Skills";
import Experience from "../section/Experience";
import Navbar from "../AppBar";
import Contact from "../section/Contact";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Center content horizontally
    justifyContent: "flex-start",
    width: "100%",
    position: "relative",
    // Add space for the fixed navbar
    maxWidth: "80%", // Default to 80% width on larger screens
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%", // Full width on small screens
      left: theme.spacing(0), // Reset left margin
    },
  },
  section: {
    marginBottom: theme.spacing(4),
    width: "100%", // Ensure sections take full width
  },
}));

export const Content = () => {
  const classes = useStyles();
  const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container component="main" className={classes.main} maxWidth="xl">
      <Navbar />

      {/* Description Section */}
      <Container component="section" className={classes.section}>
        <Typography variant="h6" component="h1" gutterBottom>
          <TextDecrypt text={`${Resume.basics.x_title}`} />
        </Typography>
        <Typography variant="h2" component="h1" gutterBottom id="info">
          <TextDecrypt text={` ${FirstName} ${LastName}.`} />
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          <TextDecrypt
            text={`${Resume.basics.job} from ${Resume.basics.location.country}.`}
          />
        </Typography>
        <Info />
      </Container>

      {/* Sections */}
      <Container component="section" className={classes.section}></Container>
      <Container component="section" className={classes.section}>
        <Projects />
      </Container>
      <Container component="section" className={classes.section}>
        <Skills />
      </Container>
      <Container component="section" className={classes.section}>
        <Experience />
      </Container>
      <Container component="section" className={classes.section}>
        <Contact />
      </Container>
    </Container>
  );
};

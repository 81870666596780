import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  Card,
  CardContent,
  Typography,
  Fade,
  Grid,
  Button,
  makeStyles,
} from "@material-ui/core";
import Resume from "../../settings/resume.json";

const useStyles = makeStyles((theme) => ({
  underline: {
    position: "relative",
    "&:after": {
      content: '""',
      display: "block",
      width: "100%",
      height: "3px",
      backgroundColor: theme.palette.primary.main,
      position: "absolute",
      bottom: "-5px",
      left: 0,
    },
  },
  centerImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "200px",
    height: "auto",
  },
  centerCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0",
  },
}));

const Experience = () => {
  const classes = useStyles();

  return (
    <div id="experience">
      <Typography
        variant="h5"
        component="h1"
        gutterBottom
        className={classes.underline}
      >
        Where I've Worked
      </Typography>
      {Resume.experience.map((element, index) => (
        <ExperienceCard key={index} experience={element} />
      ))}
    </div>
  );
};

const ExperienceCard = ({ experience }) => {
  const [ref, inView] = useInView({
    threshold: 0.5, // Trigger when half of the element is visible
    triggerOnce: false, // Only trigger once
  });

  const [showDetails, setShowDetails] = useState(false);
  const classes = useStyles();

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div
      ref={ref}
      style={{
        margin: "20px 0",
      }}
    >
      <Fade in={inView} timeout={1000}>
        <Grid container justify="center" className={classes.centerCard}>
          <Card
            style={{
              display: "flex",
              flexDirection: "column", // Align content in column
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "20px",
              width: "100%",
              border: "none",
              backgroundColor: "transparent",
            }}
          >
            <Grid item container xs={12} sm={8} spacing={2} alignItems="center">
              <Grid item xs={12} sm={4} className={classes.centerImage}>
                <img
                  src={experience.image}
                  alt={experience.company}
                  className={classes.centerImage}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <CardContent>
                  <Typography variant="h4" component="h2" gutterBottom>
                    {experience.position}
                  </Typography>
                  <Typography variant="h5" component="h3" gutterBottom>
                    {experience.company}
                  </Typography>
                  <Typography variant="body1" component="p" gutterBottom>
                    {experience.startDate} - {experience.endDate}
                  </Typography>
                  {showDetails && (
                    <Typography variant="h6" component="p">
                      {experience.summary[0]}
                    </Typography>
                  )}
                  <Button
                    variant="outlined"
                    onClick={toggleDetails}
                    style={{
                      color: "#FFAA33",
                      borderColor: "#FFAA33",
                    }}
                  >
                    {showDetails ? "Hide" : "Details"}
                  </Button>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Fade>
    </div>
  );
};

export default Experience;

import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import Resume from "../../settings/resume.json";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, CardContent, Fade, Link } from "@material-ui/core";
import LeetCodeCard from "./LeetCodeCard";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    opacity: 100,
    transform: "translateY(20px)",
    transition: "opacity 0.1s ease-out, transform 0.8s ease-out",
    "&.visible": {
      opacity: 1,
      transform: "translateY(0)",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "90vw", // Adjust for smaller screens
    },
  },
  sectionTitle: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  cardContainer: {
    background: "transparent",
    border: "none",
    margin: theme.spacing(2),
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
  relevantCourses: {
    display: "inline-block",
    fontSize: "1rem",
    opacity: 0,
    animation: "$fadeIn 5s forwards, $fadeOut 5s forwards",
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  "@keyframes fadeOut": {
    "0%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  logoImage: {
    width: "80px",
    height: "80px",
    objectFit: "contain",
    marginRight: theme.spacing(2),
  },
  highlightedSequence: {
    color: "#FFAA33", // Customize the highlight color here
    fontWeight: "bold", // Add any additional styles as needed
  },
}));

const Info = () => {
  const classes = useStyles();
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  const [currentCourseIndex, setCurrentCourseIndex] = useState(0);

  useEffect(() => {
    if (inView) {
      const intervalId = setInterval(() => {
        setCurrentCourseIndex((prevIndex) =>
          prevIndex === Resume.education[0].courses.length - 1
            ? 0
            : prevIndex + 1
        );
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [inView]);

  const keySequences = Resume.basics.keywords.sort(
    (a, b) => b.length - a.length
  ); // Array of key sequences to highlight

  const highlightKeyTerms = (line) => {
    let highlightedLine = line;
    keySequences.forEach((keySequence) => {
      const regex = new RegExp(keySequence, "gi");
      highlightedLine = highlightedLine.replace(
        regex,
        (match) =>
          `<span class="${classes.highlightedSequence}">${match}</span>`
      );
    });
    return <span dangerouslySetInnerHTML={{ __html: highlightedLine }} />;
  };

  return (
    <div ref={ref} className={`${classes.root} ${inView ? "visible" : ""}`}>
      <Grid container spacing={3}>
        {/* About Me Section */}
        <Grid item xs={12} md={6}>
          <Fade in={inView} timeout={1000}>
            <div>
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                className={classes.sectionTitle}
              >
                About Me
              </Typography>
              <Typography variant="body1">
                {Resume.basics.description.split(".").map((line, idx) => (
                  <span key={idx}>
                    {highlightKeyTerms(line)}.
                    <br />
                    <br />
                  </span>
                ))}
              </Typography>
            </div>
          </Fade>
        </Grid>

        {/* Education Section */}
        <Grid item xs={12} md={6}>
          <Fade in={inView} timeout={1000}>
            <div>
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                className={classes.sectionTitle}
              >
                Education
              </Typography>
              <div className={classes.logoContainer}>
                <a href="https://www.ulm.edu/" target="_blank" rel="noreferrer">
                  <img
                    src="ulm_academic_maroon_white.png"
                    alt="ULM Logo"
                    className={classes.logoImage}
                  />
                </a>
                <div style={{ paddingTop: "10px" }}>
                  <Typography variant="body1">
                    {Resume.education[0].institution}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {Resume.education[0].area} - {Resume.education[0].studyType}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {Resume.education[0].startDate} -{" "}
                    {Resume.education[0].endDate}
                  </Typography>
                  <Typography variant="body1">
                    Relevant courses:{" "}
                    {Resume.education[0].courses.map((course, idx) => (
                      <span
                        key={course}
                        className={classes.relevantCourses}
                        style={{
                          color: "#FFAA33",
                          display:
                            idx === currentCourseIndex
                              ? "inline-block"
                              : "none",
                        }}
                      >
                        {course}
                      </span>
                    ))}
                  </Typography>
                </div>
              </div>
            </div>
          </Fade>
          <Fade in={inView} timeout={1200}>
            <div className={classes.cardContainer}>
              <CardContent>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{
                    borderBottom: "2px solid #FFAA33",
                    paddingBottom: "8px",
                    marginBottom: "16px",
                  }}
                >
                  I like challenging myself with coding problems and I solve
                  problems on LeetCode.
                  <br />
                  Here is my LeetCode Progress Card:
                </Typography>
                <LeetCodeCard />
              </CardContent>
            </div>
          </Fade>
        </Grid>
      </Grid>
    </div>
  );
};

export default Info;

import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography, Link, useMediaQuery, Box } from "@material-ui/core";
import { TextDecrypt } from "../content/TextDecrypt";
import { HeartIcon } from "../content/SponsorButton";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    width: "100%",
    bottom: theme.spacing(0),
    left: theme.spacing(3),
    "&:hover": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
      paddingLeft: theme.spacing(2),
      transform: "rotate(270deg)", // Rotate 90 degrees on md screens
      transformOrigin: "left center", // Adjust origin if needed
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      justifyContent: "center",
      bottom: "auto",
    },
  },
  footerBox: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    minWidth: "fit-content", // Ensure minimum width based on content
  },
  heartIcon: {
    marginRight: theme.spacing(1),
    // Adjust margin between icon and text
  },
}));

export const FooterText = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box className={classes.footerContainer}>
      <Link
        color="inherit"
        underline="none"
        href="https://drive.google.com/file/d/1JvIv1UNn45sKN64_Qt25pSlWrfNGO8nO/view?usp=drive_link"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.footerBox}
      >
        <HeartIcon className={classes.heartIcon} />
        <Typography variant="caption">
          <TextDecrypt text={`\u00A0getResume();`} />
        </Typography>
      </Link>
    </Box>
  );
};

import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { TextDecrypt } from "./content/TextDecrypt";
import { Link } from "react-scroll";
import { LogoLink } from "./logo/LogoLink"; // Import your LogoLink component here

const drawerWidth = 250; // Define width for the drawer

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "none",
    justifyContent: "center",
    position: "fixed",
    top: theme.spacing(0),
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    height: "3.5rem",
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    transition: "top 0.3s", // Smooth transition for the top property
    [theme.breakpoints.down("sm")]: {
      // Hide AppBar completely for small screens (adjust as needed)
      width: "100%", // Show full width on small screens
      left: "auto", // Reset left position
      right: 0, // Align to the right side
      transform: "none", // Reset transform
    },
  },
  toolbar: {
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
  },
  button: {
    textTransform: "none",
    fontSize: "1.2rem",
    letterSpacing: "0.1em",
    margin: theme.spacing(1, 0),
    justifySelf: "right",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 2),
    },
  },
  menuButton: {
    right: theme.spacing(1), // Position on the right side
    top: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      display: "none", // Hide on desktop
    },
    // Custom size for the MenuIcon
    fontSize: "2.5rem", // Adjust the size here
    width: "2.5rem", // Adjust width
    height: "2.5rem", // Adjust height
  },
  list: {
    width: drawerWidth,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%", // Ensure the list takes full height of the drawer
  },
  listItem: {
    paddingTop: theme.spacing(2), // Adjust top padding as needed
    paddingBottom: theme.spacing(2), // Adjust bottom padding as needed
  },
  listItemText: {
    textAlign: "center", // Center align text in list items
  },
  closeButton: {
    alignSelf: "flex-end",
    top: theme.spacing(-27),
    right: theme.spacing(1),
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showAppBar, setShowAppBar] = useState(true);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerList = (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <IconButton
        className={classes.closeButton}
        onClick={toggleDrawer(false)}
        aria-label="close drawer"
      >
        <CloseIcon
          style={{
            top: theme.spacing(1), // Adjust top position as needed
          }}
        />
      </IconButton>
      <List>
        {["Info", "Projects", "Skills", "Experience"].map((text) => (
          <ListItem
            button
            key={text}
            component={Link}
            to={text}
            smooth
            duration={500}
            offset={-80}
            className={classes.listItem}
          >
            <ListItemText
              primary={<TextDecrypt text={`${text}`} />}
              className={classes.listItemText} // Apply center alignment style
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible =
        scrollPosition > currentScrollPos || currentScrollPos < 10;
      setScrollPosition(currentScrollPos);
      setShowAppBar(visible);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  return (
    <AppBar
      className={classes.appBar}
      style={{ top: showAppBar ? "0px" : "-100px" }}
    >
      <Toolbar className={classes.toolbar}>
        <LogoLink /> {/* Add your LogoLink component here */}
        {!isDesktop && (
          <Tooltip title="Menu" arrow>
            <IconButton
              edge="end" // Position at the end (right side)
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon
                style={{
                  color: theme.palette.type === "dark" ? "white" : "black",
                }}
              />
            </IconButton>
          </Tooltip>
        )}
        {isDesktop && (
          <Grid container direction="row">
            <Grid item xs={12} container justifyContent="flex-end">
              <Button
                className={classes.button}
                component={Link}
                to="info"
                smooth
                duration={500}
                offset={-80}
              >
                <TextDecrypt
                  text={"Info"}
                  style={{ fontFamily: "Arial, sans-serif" }}
                />
              </Button>
              <Button
                className={classes.button}
                component={Link}
                to="skills"
                smooth
                duration={500}
                offset={-80}
              >
                <TextDecrypt
                  text={"Skills"}
                  style={{ fontFamily: "Arial, sans-serif" }}
                />
              </Button>
              <Button
                className={classes.button}
                component={Link}
                to="projects"
                smooth
                duration={500}
                offset={-80}
              >
                <TextDecrypt
                  text={"Projects"}
                  style={{ fontFamily: "Arial, sans-serif" }}
                />
              </Button>
              <Button
                className={classes.button}
                component={Link}
                to="experience"
                smooth
                duration={500}
                offset={-80}
              >
                <TextDecrypt
                  text={"Experience"}
                  style={{
                    fontFamily:
                      'Calibre, "San Francisco", "SF Pro Text", -apple-system, system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial, sans-serif',
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        )}
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerList}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

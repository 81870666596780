import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography, useMediaQuery } from "@material-ui/core";
import { SocialIcons } from "../content/SocialIcons"; // Adjust the import path as needed
import { FooterText } from "../footer/FooterText";
import Resume from "../../settings/resume.json";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "relative",
    bottom: theme.spacing(0),
    width: "100%",
    padding: theme.spacing(1),
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(20),
    // Set a minimum height for the footer for centering
  },
  socialIconsWrapper: {
    marginTop: theme.spacing(10), // Adjust margin top for space between components
    marginBottom: theme.spacing(2), // Add margin bottom for additional space if needed
    display: "flex",
    justifyContent: "center",
    direction: "ltr",
  },
  footerText: {
    marginTop: theme.spacing(1), // Add margin top for space above social icons
    alignSelf: "center", // Align text to center horizontally
  },
}));

const Contact = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <footer className={classes.footer}>
      {isSmallScreen && (
        <>
          <FooterText className={classes.footerText} />
          <div className={classes.socialIconsWrapper}>
            <SocialIcons footer />
          </div>
        </>
      )}

      <Typography variant="caption">© 2024 {Resume.basics.name} </Typography>
    </footer>
  );
};

export default Contact;

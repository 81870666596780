import React from "react";
import { LogoLink } from "../components/logo/LogoLink";
import { Content } from "../components/content/Content";
import { Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DisplacementSphere from "../components/background/DisplacementSphere";
import { ThemeToggle } from "../components/theme/ThemeToggle";
import { FooterText } from "../components/footer/FooterText";
import { SocialIcons } from "../components/content/SocialIcons";
import { SpeedDials } from "../components/speedDial/SpeedDial";

const useStyles = makeStyles(() => ({
  "@global": {
    "*": {
      fontFamily:
        'Calibre, "San Francisco", "SF Pro Text", -apple-system, system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial, sans-serif',
    },
  },
  root: {
    paddingTop: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    minHeight: "100vh",
    scrollBehavior: "smooth",
    width: "100%",
    position: "relative",
  },
  section: {
    padding: "20px",
    margin: "20px 0",
  },
  NavBar: {
    position: "fixed",
    top: "0",
    width: "100%",
    zIndex: "1000",
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DisplacementSphere />

      <Content />
      <ThemeToggle />
      <Hidden smDown>
        <SocialIcons />
      </Hidden>
      <Hidden mdUp>
        <SpeedDials />
      </Hidden>
      <Hidden smDown>
        <FooterText />
      </Hidden>
    </div>
  );
};

export default Home;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  colors,
} from "@material-ui/core";
import { OpenInNew, CheckCircle } from "@material-ui/icons";
import Resume from "../../settings/resume.json";

const useStyles = makeStyles((theme) => ({
  projectsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    minWidth: "75vw",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    position: "relative",
  },
  card: {
    position: "relative",
    minWidth: 300, // Default size for larger screens
    minHeight: 400, // Default size for larger screens
    margin: theme.spacing(1),
    borderRadius: "20px",
    perspective: "1000px",
    cursor: "pointer",
    transition: "transform 0.6s",
    "&:hover": {
      transform: "scale(1.05)",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "70%", // Adjusted size for smaller screens
    },
  },
  cardInner: {
    width: "100%",
    height: "100%",
    position: "relative",
    borderRadius: "20px",
    transformStyle: "preserve-3d",
    transition: "transform 0.6s",
    "&.flipped": {
      transform: "rotateY(180deg)",
    },
  },
  cardFront: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backfaceVisibility: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  cardBack: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backfaceVisibility: "hidden",
    backgroundColor: theme.palette.background.paper,
    transform: "rotateY(180deg)",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "20px",
  },
  image: {
    width: "100%",
    minHeight: 300,
    borderRadius: "20px 20px 0 0",
    borderStyle: "line",
    borderWidth: "1px",
  },
  underline: {
    position: "relative",
    "&:after": {
      content: '""',
      display: "block",
      width: "100%",
      height: "3px",
      backgroundColor: theme.palette.primary.main,
      position: "absolute",
      bottom: "-5px",
      left: 0,
    },
  },
  techUsed: {
    textAlign: "left",
    color: colors.grey[500],
    alignSelf: "stretch",
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
  },
  linkContainer: {
    marginTop: "-25px", // Adjust as needed
    width: "100%",
    paddingTop: theme.spacing(1),
  },
  cardContent: {
    flexGrow: 1,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0, // Adjust as needed
    marginLeft: 0, // Adjust as needed
    alignItems: "flex-start",
  },
  listItemIcon: {
    minWidth: "20px", // Adjust as needed
    padding: 0,
    alignSelf: "flex-start",
  },
  listItemText: {
    fontSize: "0.65rem", // Adjust the font size here
    margin: 0, // Remove any default margins
    alignItems: "flex-start",
  },
}));

const Projects = () => {
  const classes = useStyles();

  return (
    <div id="projects">
      <Typography
        variant="h5"
        component="h5"
        gutterBottom
        className={classes.underline}
      >
        Some Things I've Built
      </Typography>

      <Box className={classes.projectsContainer}>
        {Resume.projects.map((project, index) => (
          <ProjectCard key={index} project={project} classes={classes} />
        ))}
      </Box>
    </div>
  );
};

const ProjectCard = ({ project, classes }) => {
  const [flipped, setFlipped] = useState(false);

  const toggleFlipped = () => {
    setFlipped(!flipped);
  };

  return (
    <Card className={classes.card} onClick={toggleFlipped}>
      <div className={`${classes.cardInner} ${flipped ? "flipped" : ""}`}>
        <div className={classes.cardFront}>
          <img
            src={project.image}
            alt={project.name}
            className={classes.image}
          />
          <Typography
            variant="h6"
            component="h2"
            gutterBottom
            style={{ color: "#FFAA33", alignSelf: "baseline" }}
          >
            {project.name}
          </Typography>
          <Typography
            variant="caption"
            component="p"
            className={classes.techUsed}
          >
            {project.techUsed}
          </Typography>
        </div>
        <div className={classes.cardBack}>
          <CardContent className={classes.cardContent}>
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              style={{ color: "#FFAA33" }}
            >
              Highlights
            </Typography>
            <List>
              {project.highlights.map((highlight, index) => (
                <ListItem key={index} className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <CheckCircle
                      style={{ color: "#FFAA33", fontSize: "1rem" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={highlight}
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
          <div className={classes.linkContainer}>
            <Link
              href={project.url}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
              color="inherit"
              style={{
                textDecoration: "underline",
                color: "#3f51b5",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h7" component="h9">
                Visit Github Repository
              </Typography>
              <OpenInNew fontSize="small" />
            </Link>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Projects;

import { Typography } from "@material-ui/core";
import React from "react";

const LeetCodeCard = () => {
  const cardUrl = "https://leetcard.jacoblin.cool/BiswashNK?ext=heatmap";

  return (
    <img
      src={cardUrl}
      alt="LeetCode Card"
      style={{ width: "100%", height: "auto" }}
    />
  );
};

export default LeetCodeCard;

import React from "react";
import { useInView } from "react-intersection-observer";
import Resume from "../../settings/resume.json";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Fade, Grid } from "@material-ui/core";
import { Color } from "three";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    width: "100%",
    opacity: 0,
    transform: "translateY(20px)",
    transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
    "&.visible": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  skillItem: {
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  card: {
    minWidth: 275,
    margin: theme.spacing(2),
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  categoryTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
    borderRadius: "4px 4px 0 0",
  },
  keywordList: {
    padding: theme.spacing(2),
  },
  underline: {
    position: "relative",
    "&:after": {
      content: '""',
      display: "block",
      width: "100%",
      height: "3px",
      backgroundColor: theme.palette.primary.main,
      position: "absolute",
      bottom: "-5px",
      left: 0,
    },
  },
}));

const Skills = () => {
  const classes = useStyles();

  return (
    <div id="skills">
      <Typography
        variant="h5"
        component="h1"
        gutterBottom
        className={classes.underline}
      >
        Some of my skills
      </Typography>
      <Grid container spacing={3}>
        {Resume.skills.map((skillCategory, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <SkillCard skillCategory={skillCategory} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const SkillCard = ({ skillCategory }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const classes = useStyles();

  return (
    <div ref={ref} className={`${classes.main} ${inView ? "visible" : ""}`}>
      <Fade in={inView} timeout={800}>
        <Card className={classes.card}>
          <Typography className={classes.categoryTitle}>
            {skillCategory.name}
          </Typography>
          <CardContent className={classes.keywordList}>
            {skillCategory.keywords.map((keyword, idx) => (
              <Typography
                key={idx}
                variant="body1"
                component="p"
                className={classes.skillItem}
              >
                {keyword.x_icon ? (
                  <i className={`icon ${keyword.x_icon}`}></i>
                ) : (
                  <img
                    src={keyword.dev_icon}
                    alt="x"
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                )}
                {" " + keyword.name}
              </Typography>
            ))}
          </CardContent>
        </Card>
      </Fade>
    </div>
  );
};

export default Skills;

import React from "react";
import { Link, Tooltip, Typography, Zoom } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Resume from "../../settings/resume.json";
import { Logo } from "./Logo";

const useStyles = makeStyles((theme) => ({
  svg: {
    display: "flex",
    alignItems: "center",

    height: "3rem",
    width: "3rem",
    position: "absolute",
    left: 0,
    top: theme.spacing(0),
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
    zIndex: 2,
    margin: theme.spacing(1),

    borderRadius: "50%",
  },
  logo: {
    marginRight: theme.spacing(1), // Space between logo and text
  },
  text: {
    color: "white",
    justifySelf: "flex-end",
    fontFamily: "Brush Script MT, Brush Script Std, cursive",
  },
}));

export const LogoLink = () => {
  const classes = useStyles();

  return (
    <Tooltip
      title={Resume.basics.name}
      placement="right"
      TransitionComponent={Zoom}
    >
      <Link
        variant="h6"
        href={Resume.basics.url}
        underline="none"
        color="inherit"
        noWrap
        className={classes.svg}
      >
        <Logo />
      </Link>
    </Tooltip>
  );
};

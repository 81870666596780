import React from "react";
import {
  Link,
  Tooltip,
  IconButton,
  Zoom,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Resume from "../../settings/resume.json";
import LeetCodeIcon from "./LeetCodeIcon";

const useStyles = makeStyles((theme) => ({
  socialIcons: {
    display: "flex",
    flexDirection: (props) => (props.vertical ? "column" : "row"),
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
    gap: theme.spacing(2),
    position: (props) => (props.footer ? "relative" : "fixed"),
    bottom: (props) => (props.footer ? "auto" : theme.spacing(15)),
    right: (props) => (props.footer ? "auto" : theme.spacing(2)),
    [theme.breakpoints.down("sm")]: {
      right: (props) => (props.footer ? "auto" : theme.spacing(1)),
      bottom: (props) => (props.footer ? "auto" : theme.spacing(1)),
    },
  },
  iconButton: {
    height: "2.5rem",
    width: "2.5rem",
  },
  icon: {
    fontSize: "1.25rem",
  },
}));

export const SocialIcons = ({ footer }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles({ footer, vertical: !isSmallScreen });

  const socialItems = Resume.basics.profiles.map((socialItem) => (
    <Link
      href={socialItem.url}
      key={socialItem.network.toLowerCase()}
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
      color="inherit"
    >
      <Tooltip
        title={socialItem.username}
        placement={isSmallScreen ? "top" : "left"}
        TransitionComponent={Zoom}
      >
        <IconButton
          color="inherit"
          aria-label={socialItem.network}
          className={classes.iconButton}
        >
          {socialItem.network.toLowerCase() === "leetcode" ? (
            <LeetCodeIcon className={classes.icon} />
          ) : (
            <i className={`${classes.icon} ${socialItem.x_icon}`}></i>
          )}
        </IconButton>
      </Tooltip>
    </Link>
  ));

  return <div className={classes.socialIcons}>{socialItems}</div>;
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logo: {
    position: "relative",
    top: theme.spacing(0),

    height: "100%",
    width: "100%",
    left: theme.spacing(0),
    borderRadius: "50%",
    zIndex: 1,
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
    transition: "all 0.5s ease",
    // Media query to hide on small screens
  },
}));

export const Logo = () => {
  const classes = useStyles();

  return <img className={classes.logo} src="/logo.jpg" alt="Logo" />;
};
